/**
 * Created by sky on 2019/4/4.
 */
import axios from 'axios';




let init_api_pre = '/index.php?action='
let hotel_api_pre = (version) => {
    return "/sys/" + version + "/data/public/index.php"
}


if (process.env.NODE_ENV === 'production') {
    init_api_pre = '../../index.php?action='
    hotel_api_pre = (version) => {
        return "../../sys/" + version + "/data/public/index.php"
    }
}

const axios_config = {
    timeout: 60 * 1000,
}

const axios_pro = axios.create(axios_config);


// 添加请求拦截器
axios_pro.interceptors.request.use(function (config) {
    if (localStorage['locale']) {
        config.headers['Accept-Language'] = localStorage['locale']
    }

    if (localStorage['admin_msg'] && localStorage['admin_msg'] != 'null' && localStorage['admin_msg'] != 'undefined') {
        let admin_msg = JSON.parse(localStorage['admin_msg']);
        config.headers.AUTHTOKEN = admin_msg['user_token']
    }

    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});


export { init_api_pre, hotel_api_pre };

export default axios_pro;