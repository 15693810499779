/**
 * Created by sky on 2019/3/28.
 */

import {global} from './global'
import {admin} from './admin';


var Reducers={
    global,
    admin
};
export default Reducers;
