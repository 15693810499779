import React, { useState, useEffect } from 'react';
import styles from './DownLoadWeb.less'
import qs from 'qs';
import axios from 'axios';
import $, { ajax } from 'jquery'
import md5 from 'md5'
import moment from 'moment'
//antd组件
import {
    message,
    Table,
    Avatar,
    Card,
    Switch,
    Input,
    Select,
    DatePicker,
    Button,
    Tag,
    Progress,
    InputNumber,
    PageHeader,
    Modal,
    Tabs,
    Badge, List, Skeleton, Form, Icon, Spin, Steps
} from 'antd';

// mc
import { connect } from 'react-redux';
import Actions from '@/mc/actions/Actions';
import Api from '@/mc/api/Api';

const Index = (props) => {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(false);

    const [login_type, setLogin_type] = useState();
    const [status, setStatus] = useState();
    const [apply_msg, setApply_msg] = useState({});
    const [qrcode, setQrcode] = useState();
    const [version, setVersion] = useState();
    const [version_url, setVersion_url] = useState();
    const [dataBaseStatus, setDataBaseStatus] = useState(false);
    const [linkStyle, setLinkStyle] = useState({});

    const [step_current, setStep_current] = useState(0);

    useEffect(() => {
        getLoginType()
    }, []);

    useEffect(() => {
        if (login_type) {
            getCustomerStatus()
        }
    }, [login_type]);

    useEffect(() => {
        if (status == 11) {
            createXcxPageQrCode()
        }
        if (status == 2) {
            newCustomer()
        }
        if (status == 5) {
            if (!dataBaseStatus) {
                testConnectDatabase()
                return;
            }
            downLoadVersion()
        }
        if (status == 6) {
            if (version && version_url) {
                Modal.success({
                    title: '提示',
                    content: `当前系统版本：${version}，正在自动跳转中`
                })
                if (login_type === 'we7') {
                    auto_login(version, version_url)
                } else {
                    window.location.href = version_url;
                }
            }

        }
    }, [status, dataBaseStatus, login_type, version, version_url]);

    useEffect(() => {
        if (status == 11 || status == 121 || status == 122 || status == 123) {
            setStep_current(0)
        }
        if (status == 5 && !dataBaseStatus) {
            setStep_current(1)
        }
        if (status == 5 && dataBaseStatus) {
            setStep_current(2)
        }
        if (status == 6) {
            setStep_current(3)
        }
    }, [status, dataBaseStatus]);

    const getLoginType = () => {
        Api.init.getLoginType().then((rs) => {
            setLogin_type(rs)
        })
    }

    const getCustomerStatus = () => {
        //判断客户状态
        // 886886   黑名单ip
        // 11    ip未授权，未申请
        // 121   ip未授权，已申请, 等待审核，请联系管理员尽快审核！
        // 122   ip未授权，审核通过！请联系管理员！
        // 123   ip未授权，已申请, 审核拒绝，请联系管理员！
        // 2     本ip下未创建客户，请创建
        // 3     请传激活码
        // 4     激活码错误
        // 5     未安装系统
        // 6     已安装系统，查询版本成功！
        setLoading(true)
        Api.init.getCustomerStatus().then((rs) => {
            if (rs.code == 886886) {
                Modal.error({
                    title: '提示',
                    content: rs.msg
                })
                return;
            }
            setLoading(false)
            setStatus(rs.code);
            getLinkStyle()
            if (rs.code == 121 || rs.code == 122 || rs.code == 123) {
                setApply_msg(rs.data)
            }
            if (rs.code == 3 || rs.code == 4) {
                Modal.error({
                    title: '提示',
                    content: rs.msg
                })
            }
            if (rs.code == 6) {
                setVersion(rs.data['version'])
                setVersion_url(rs.data['version_url'])
            }
        })
    }
    const createXcxPageQrCode = () => {
        setLoading(true)
        Api.init.createXcxPageQrCode().then((rs) => {
            setLoading(false)
            if (rs.code == 0) {
                setQrcode(rs.data)
            } else {
                Modal.error({
                    title: '提示',
                    content: rs.msg
                })
            }
        })
    }

    const getLinkStyle = () => {
        setLoading(true)
        Api.init.getLinkStyle().then((rs) => {
            setLoading(false)
            if (rs.code == 0) {
                setLinkStyle(rs.data)
            } else {
                Modal.error({
                    title: '提示',
                    content: rs.msg
                })
            }
        })
    }

    const testConnectDatabase = () => {
        setLoading(true)
        Api.init.testConnectDatabase().then((rs) => {
            setLoading(false)
            if (rs.code == 0) {
                setDataBaseStatus(true)
            } else {
                setDataBaseStatus(false)
                // Modal.error({
                //     title: '提示',
                //     content: rs.msg
                // })
            }
        })
    }

    const submit_database = (params) => {
        setLoading(true)
        Api.init.setDataBaseConfig(qs.stringify(params)).then((rs) => {
            setLoading(false)
            if (rs.code == 0) {
                testConnectDatabase()
            } else {
                Modal.error({
                    title: '提示',
                    content: rs.msg
                })
            }
        })
    }

    const newCustomer = () => {
        Api.init.newCustomer().then((rs) => {
            if (rs.code == 0) {
                setStatus(5)
            } else {
                Modal.error({
                    title: '提示',
                    content: '新建站点失败'
                })
            }
        })
    }

    const downLoadVersion = async () => {
        setProgress(50)
        let start_time = moment()
        let rs = await Api.init.downLoadVersion()
        if (rs.code != 0) {
            Modal.error({
                title: '提示',
                content: rs.msg
            })
            return
        }
        setProgress(80)

        let version = rs.data['version']
        let version_url = rs.data['version_url']
        let use_time = rs.data['use_time']
        //插入数据库 
        let rs_initDataBase = await Api.init.initDataBase({}, version)
        if (rs_initDataBase.code != 0) {
            Modal.error({
                title: '提示',
                content: rs_initDataBase.msg
            })
            return
        }
        setProgress(85)

        if (login_type !== 'we7') {
            let end_time = moment()
            let used_time = end_time.diff(start_time, 'second')
            //如果不是来源于微擎，将直接跳转登录
            setProgress(100)
            setStep_current(3)
            Modal.info({
                title: '提示',
                content: '初始化成功，耗时： ' + used_time + ' 秒',
                okText: '前往系统',
                onOk: () => {
                    window.location.href = version_url;
                }
            })
            return;
        }

        let login_params = {
            name: 'admin',
            pwd: md5("123456")
        }
        let rs_operatorLogin = await Api.init.operatorLogin(qs.stringify(login_params), version);
        if (rs_operatorLogin.code != 0) {
            Modal.error({
                title: '提示',
                content: rs_operatorLogin.msg
            })
            return;
        }
        localStorage['admin_msg'] = JSON.stringify(rs_operatorLogin.data);
        setProgress(90)

        let shop_params = {
            shop_name: '第一个酒店',
            lang: '1',
            limit_num: 1,
            limit_time: moment().add(1, 'year').unix(),
        }
        let rs_add_shop = await Api.init.createShop(qs.stringify(shop_params), version);
        if (rs_add_shop.code != 0) {
            Modal.error({
                title: '提示',
                content: rs_add_shop.msg
            })
            return;
        }
        setProgress(92)

        let auth_list_rs = await Api.init.getCustomerAuth();
        let auth_list = auth_list_rs.data;

        let auth_params = {
            shop_id: rs_add_shop.data.id,
            auth_list: JSON.stringify(auth_list)
        }

        let rs_update_auth = await Api.init.updateShopAuth(qs.stringify(auth_params), version);
        if (rs_update_auth.code != 0) {
            Modal.error({
                title: '提示',
                content: rs_update_auth.msg
            })
            return;
        }
        setProgress(93)


        let rs_login_out = await Api.init.loginOut(qs.stringify({}), version);
        if (rs_login_out.code != 0) {
            Modal.error({
                title: '提示',
                content: rs_login_out.msg
            })
            return;
        }
        setProgress(94)

        auto_login(version, version_url);
    }

    const auto_login = async (version, version_url) => {
        let rs_getNamePwdOfFirstShop = await Api.init.getNamePwdOfFirstShop(qs.stringify({}), version);
        if (rs_getNamePwdOfFirstShop.code != 0) {
            Modal.error({
                title: '提示',
                content: '获取账号失败！'
            })
            return;
        }
        setProgress(95)

        let params = {
            name: rs_getNamePwdOfFirstShop.data.name,
            pwd: md5("123456")
        }
        let rs_login = await Api.init.login(qs.stringify(params), version);
        if (rs_login.code != 0) {
            Modal.error({
                title: '提示',
                content: rs_login.msg
            })
            return;
        }
        setProgress(100)
        localStorage['admin_msg'] = JSON.stringify(rs_login.data)
        window.location.href = version_url;
    }

    return (
        <div className={styles.content}>
            {
                login_type === 'normal' && (
                    <div className={styles.steps}>
                        <Steps current={step_current} >
                            <Steps.Step title="申请ip授权" />
                            <Steps.Step title="数据库配置" />
                            <Steps.Step title="安装系统" />
                            <Steps.Step title="完成" />
                        </Steps>
                    </div>
                )
            }
            {
                status == 11 && (
                    <div className={styles.content_box}>
                        <div>
                            <div style={{ width: '100%', marginBottom: 20, display: 'flex', justifyContent: 'center' }}>
                                <img src={qrcode} style={{ width: 300, height: 300 }} />
                            </div>
                            <h3>请先扫码申请 ip 授权，申请后页面会自动进入下一步，无需操作</h3>
                        </div>
                    </div>
                )
            }
            {
                (status == 121 || status == 122 || status == 123) && (
                    <div className={styles.content_box}>
                        <div>
                            <h1>
                                申请 ID：{apply_msg.id}
                            </h1>
                            <div>
                                公司名称：{apply_msg.company_name}
                            </div>
                            <div>
                                联系人：{apply_msg.link_man}
                            </div>
                            <div>
                                联系电话：{apply_msg.link_phone}
                            </div>
                            <div>
                                QQ：{apply_msg.qq}
                            </div>
                            <div>
                                IP：{apply_msg.ip}
                            </div>
                            <div>
                                域名：{apply_msg.domain}
                            </div>
                            <div>
                                <h3 style={{ marginTop: 24 }}>
                                    {
                                        apply_msg.status == 1 && '等待审核中，请联系管理员审核！若审核完毕，刷新页面进入下一步'
                                    }
                                    {
                                        apply_msg.status == 2 && '审核通过！但数据异常，请联系管理员！'
                                    }
                                    {
                                        apply_msg.status == 3 && '审核拒绝，请联系管理员！'
                                    }
                                </h3>
                                <div style={{ marginTop: 16 }}>
                                    qq服务群：{linkStyle.qq_group}
                                </div>
                            </div>

                        </div>
                    </div>
                )
            }
            {
                (status == 5 && !dataBaseStatus && login_type !== 'we7') && (
                    <div className={styles.content_box}>
                        <div style={{ width: 400 }}>
                            <DataBaseForm submit={submit_database} />
                        </div>
                    </div>
                )
            }
            {
                (status == 5 && dataBaseStatus) && (
                    <div className={styles.content_box}>
                        <div>
                            <div style={{ width: '100%', marginBottom: 20, display: 'flex', justifyContent: 'center' }}>
                                {
                                    progress == false ?
                                        <div style={{ marginBottom: 16 }}>
                                            <Spin size={"large"} > </Spin>
                                        </div>
                                        :
                                        <Progress type="circle" percent={progress} />
                                }
                            </div>
                            <h3>初次使用，需要初始化，根据您的服务器性能，约1分钟左右，完成后将自动跳转，请耐心等待...</h3>
                        </div>
                    </div>
                )
            }
            {
                status == 6 && (
                    <div className={styles.content_box}>
                        <div style={{ color: 'green', fontSize: 50, }}>
                            <Icon type="check-circle" />
                        </div>
                        <h1>系统已初始化完成</h1>
                        {/* <a href={version_url}>
                            <Button>前往系统</Button>
                        </a> */}
                    </div>
                )
            }
            {
                loading && (
                    <div className={styles.loading} >
                        <Spin />
                    </div>
                )
            }
        </div>
    );
}



const DataBaseForm = Form.create({ name: 'DataBaseForm' })(
    (props) => {
        const onSubmit = (e) => {
            e.preventDefault();
            props.form.validateFields((err, values) => {
                if (!err) {
                    let params = {
                        ...values
                    }
                    props.submit && props.submit(params)
                }
            });
        }
        const { getFieldDecorator } = props.form;
        const formItemLayout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            labelAlign: 'left',
            // hideRequiredMark:true
        };

        return (
            <Form
                {...formItemLayout}
                onSubmit={onSubmit}
                layout="horizontal"
            >
                <Form.Item
                    label="主机"
                    help="本地连接请输入：127.0.0.1"
                >
                    {getFieldDecorator('DB_HOST', {
                        rules: [{ required: true, message: '请输入主机地址' }],
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item
                    label="端口"
                    help="一般为 3306"
                >
                    {getFieldDecorator('DB_PORT', {
                        rules: [{ required: true, message: '请输入端口' }],
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item
                    label="用户名"
                >
                    {getFieldDecorator('DB_USER', {
                        rules: [{ required: true, message: '请输入用户名' }],
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item
                    label="密码"
                >
                    {getFieldDecorator('DB_PWD', {
                        rules: [{ required: true, message: '请输入密码' }],
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item
                    label="数据库名"
                >
                    {getFieldDecorator('DB_NAME', {
                        rules: [{ required: true, message: "请输入数据库名" }],
                    })(
                        <Input />
                    )}
                </Form.Item>

                <Form.Item wrapperCol={{ span: 24 }}>
                    <Button block type="primary" htmlType="submit">
                        下一步
                    </Button>
                </Form.Item>
            </Form>
        )
    }
)


export default Index;